"use client";

import { NoItemsPlaceholder } from "@/components";

export default function Error({
  reset,
}: {
  error: Error & { digest?: string };
  reset: () => void;
}) {
  return (
    <NoItemsPlaceholder
      title="An Unexpected Error Ocurred"
      subtitle="Please try again"
      buttonText="Reload"
      onClick={reset}
    />
  );
}
